export const milestoneStatus = {
  inProgress: 'in_progress',
  pendingApproval: 'pending_approval',
  paid: 'paid',
  declined: 'declined',
  workPending: 'work_pending',
};

export const INVOICE_STATUS_OPTIONS = {
  pending_approval: {
    title: 'Pending Approval',
    section_title: 'Pending Approval',
    color: '#ff9f2d',
    colorKey: 'orange',
  },
  in_progress: {
    title: 'In Progress',
    section_title: 'In Progress',
    color: '#0075FF',
    colorKey: 'blue',
  },
  work_pending: {
    title: 'Work Pending',
    section_title: "Haven't Started",
    color: '#969696',
    colorKey: 'gray',
  },
  paid: {
    title: 'Paid',
    section_title: 'Paid',
    color: '#48a039',
    colorKey: 'green',
  },
  declined: {
    title: 'Declined',
    section_title: 'Declined',
    color: '#5a2dff',
    colorKey: 'purple',
  },
};

export default milestoneStatus;
