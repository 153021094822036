import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  name: {
    fontSize: 28,
    flexGrow: 1,
    fontFamily: 'Open Sans',
    alignSelf: 'flex-end',
    justifySelf: 'flex-start',
  },
  receiptHeader: {
    fontFamily: 'Open Sans',
    fontSize: 24,
    flexGrow: 0,
    color: '#888888',
    textDecoration: 'none',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
  rowMargin: {
    marginTop: '5px',
  },
  contactColumn: {
    flexDirection: 'column',
    flexGrow: 9,
  },
  contactText: {
    fontSize: 10,
    fontFamily: 'Open Sans',
  },
  receiptDataColumn: {
    fontFamily: 'Open Sans',
    alignSelf: 'flex-start',
    flexGrow: 1,
  },
  receiptDataRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    fontSize: 10,
  },
  receiptDataKey: {
    flexGrow: 2,
  },
  receiptDataValue: {
    flexGrow: 0,
  },
});

export default function Header({ organization, invoice, payment }) {
  return (
    <View>
      <View style={styles.row}>
        <Text style={styles.name}>{organization.name}</Text>
        <Text style={styles.receiptHeader}>Receipt</Text>
      </View>
      <View style={{ ...styles.row, ...styles.rowMargin }}>
        <View style={styles.contactColumn}>
          {organization.help_options?.email?.enabled && (
            <Text style={styles.contactText}>
              {organization.help_options.email.value}
            </Text>
          )}
          {organization.help_options?.phone?.enabled && (
            <Text style={styles.contactText}>
              {organization.help_options.phone.value}
            </Text>
          )}
        </View>

        <View style={styles.receiptDataColumn}>
          {[
            { key: 'Receipt Number:', value: payment.id.toUpperCase() },
            { key: 'Invoice Number:', value: invoice.id.toUpperCase() },
            {
              key: 'Date Paid:',
              value: !isNaN(new Date(payment.date).getTime())
                ? new Date(payment.date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })
                : payment.date,
            },
            {
              key: 'Payment Method:',
              value: payment.payment_method,
            },
          ].map(({ key, value }) => (
            <View key={key} style={styles.receiptDataRow}>
              <Text style={styles.receiptDataKey}>{key}</Text>
              <Text style={styles.receiptDataValue}>{value}</Text>
            </View>
          ))}
        </View>
      </View>
    </View>
  );
}

Header.propTypes = {
  organization: PropTypes.object.isRequired,
  invoice: PropTypes.object.isRequired,
  payment: PropTypes.object.isRequired,
};
