/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import './LineItemEditor.css';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { HiOutlineTrash } from 'react-icons/hi';

export const currencyFormatter = {
  format: (value) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      // Cap it at 2 decimal places
      minimumFractionDigits: 2,
    });

    return formatter.format(value / 100);
  },
};

export const id = () =>
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15);

function LineItemEditor({
  lineItems = [],
  setLineItems,
  editable,
  milestoneId,
}) {
  function addLineItem() {
    const lineItemsClone = _.cloneDeep(lineItems);

    lineItemsClone.push({
      id: id(),
      description: '',
      rate: 0,
      quantity: 0,
    });

    setLineItems(lineItemsClone);
  }

  function deleteLineItem(index) {
    const lineItemsClone = _.cloneDeep(lineItems);

    lineItemsClone.splice(index, 1);
    setLineItems(lineItemsClone);
  }

  function updateLineItem(index, key, newValue) {
    const lineItemsClone = _.cloneDeep(lineItems);

    lineItemsClone[index][key] = newValue;

    setLineItems(lineItemsClone);
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reoderedLineItems = reorder(
      lineItems,
      result.source.index,
      result.destination.index
    );

    setLineItems(reoderedLineItems);
  }

  return (
    <div>
      {' '}
      <div className="li-table table-responive table-design2">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={milestoneId} type="line-item">
            {(provided) => (
              <table className="table" ref={provided.innerRef} cellpadding="5">
                <thead className="table-head">
                  <tr>
                    <th>Description</th>
                    <th>Qty</th>
                    <th>Rate</th>
                    <th>Total</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {/* description would be array */}

                  {lineItems?.length !== 0
                    ? lineItems &&
                      lineItems.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                          type="line-item"
                        >
                          {(provided) => (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <td style={{ width: '400px' }}>
                                <input
                                  className="li-input"
                                  placeholder="Line Item Description"
                                  onChange={(event) => {
                                    updateLineItem(
                                      index,
                                      'description',
                                      event.target.value
                                    );
                                  }}
                                  value={item.description}
                                />
                              </td>
                              <td style={{ width: '100px' }}>
                                {' '}
                                <input
                                  type="number"
                                  className="li-input"
                                  onChange={(event) => {
                                    // Use parsse float to allow decimals
                                    // Cap it at 2 decimal places
                                    updateLineItem(
                                      index,
                                      'quantity',
                                      parseFloat(event.target.value)
                                    );
                                  }}
                                  value={item.quantity}
                                  disabled={!editable}
                                />
                              </td>
                              <td style={{ width: '100px' }}>
                                {' '}
                                <input
                                  className="li-input"
                                  type="number"
                                  onChange={(event) => {
                                    updateLineItem(
                                      index,
                                      'rate',
                                      parseFloat(event.target.value)
                                    );
                                  }}
                                  value={item.rate}
                                  disabled={!editable}
                                />
                              </td>
                              <td
                                style={{
                                  width: '100px',
                                  padding: '8px 10px',
                                }}
                              >
                                {currencyFormatter.format(
                                  (item.quantity || 0) * (item.rate || 0) * 100
                                )}
                              </td>
                              <td style={{ width: '50px' }}>
                                <HiOutlineTrash
                                  className={`table-trash-icon ${
                                    editable ? '' : 'disabled'
                                  }`}
                                  onClick={() => {
                                    if (editable) {
                                      deleteLineItem(index);
                                    }
                                  }}
                                  // eslint-disable-next-line no-return-assign
                                  disabled={!editable}
                                />
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      ))
                    : [1].map(() => (
                        <tr>
                          <td style={{ padding: '20px' }}>No Line Items</td>
                        </tr>
                      ))}
                  {provided.placeholder}
                </tbody>
              </table>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className="footer-wrapper">
        <div
          onClick={() => {
            addLineItem();
          }}
          thin
          padding="0"
          disabled={!editable}
          className="add-li-button"
        >
          Add line item
        </div>

        <p>
          Subtotal
          <span className="subtotal">
            {currencyFormatter.format(
              lineItems.reduce(
                (accumulator, currentValue) =>
                  accumulator + currentValue.quantity * currentValue.rate,
                0
              ) * 100
            )}
          </span>
        </p>
      </div>
    </div>
  );
}

export default LineItemEditor;
