import React from 'react';
import { Card } from 'antd';
import CollaboratorCard from './CollaboratorCard';
import AutoPayLabel from './AutoPay/AutoPayLabel';

// import 'antd/dist/antd.css';


import { numToMonth } from '../../../utils';

export default function ProjectHeaderCard({
  currentUser,
  project,
  collaborators,
  invites,
}) {
  const dateStarted = project.date_started.toDate
    ? project.date_started.toDate()
    : project.date_started;

  return (
    <>
      <div style={{ paddingBottom: '10px' }}>
        <div className="project-header-no-edit">
          <div className="project-header-name">{project.name}</div>
          <div className="project-header-dates">
            {`Started ${numToMonth(
              dateStarted.getMonth()
            )} ${dateStarted.getDate()} ${dateStarted.getFullYear()}`}
          </div>
        </div>
      </div>

      {project.autoPay && project.autoPay.enabled && (
        <AutoPayLabel project={project} currentUser={currentUser} />
      )}

      {project.collaborators.map(collaborator => {
        if (currentUser.id === collaborator.user) {
          currentUser.role = collaborator.role;
        }
        const collaboratorData = collaborators[collaborator.user]
          ? collaborators[collaborator.user].data
          : null;

        if (collaboratorData) {
          collaboratorData.role = collaborator.role;
          return (
            <CollaboratorCard
              key={collaborator.user}
              collaborator={collaboratorData}
              project={project}
              currentUser={currentUser}
            />
          );
        }
        // Return a loading card if the collaborator data hasn't fetched yet
        return (
          <Card
            key={collaborator.user}
            style={{ height: 63, border: 'none' }}
            loading
          />
        );
      })}
      {project.invites.map(invite => {
        return (
          <CollaboratorCard key={invite} isInvite invite={invites[invite]} />
        );
      })}
    </>
  );
}
