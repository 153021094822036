/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Input,
  Button,
  Select,
  message,
  Modal,
  Popconfirm,
  DatePicker,
  Switch,
} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { db } from '../../../fire';
import EditCollaboratorCard from './EditCollaboratorCard';
import EditCollaboratorInviteCard from './EditCollaboratorInviteCard';
import {
  updateProject,
  archiveProject,
  addUserToProject,
} from '../../../actions/project';
import {
  removeCollaboratorFromProject,
  updateCollaboratorRoleInProject,
} from '../../../actions/collaborator';
import {
  createInviteForProject,
  removeInviteFromProject,
  updateInviteRoleInProject,
} from '../../../actions/invites';
import { dayJsToTimestamp } from '../../../utils';
import AutoPaySettings, {
  validateAutoPaySettings,
} from './AutoPay/AutoPaySettings';
import DefaultPayerSetting from './DefaultPayerSettings';

const { Option } = Select;

const mapDispatchToProps = {
  updateProject,
  archiveProject,
  addUserToProject,
  removeCollaboratorFromProject,
  updateCollaboratorRoleInProject,
  createInviteForProject,
  removeInviteFromProject,
  updateInviteRoleInProject,
};

function EditProjectHeaderCard({
  project,
  currentUser,
  allCollaborators,
  invites,
  archiveProject,
  addUserToProject,
  cancelEdit,
  updateProject,
  removeCollaboratorFromProject,
  updateCollaboratorRoleInProject,
  createInviteForProject,
  removeInviteFromProject,
  updateInviteRoleInProject,
}) {
  // eslint-disable-next-line react/destructuring-assignment
  const [projectChanges, setProjectChanges] = useState(project);
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('viewer');
  // eslint-disable-next-line no-unused-vars
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState(project.currency || 'USD');

  const handleUnarchive = () => {
    archiveProject(project, false)
      .then(() => {
        setProjectChanges({
          ...projectChanges,
          archived: false,
        });
        message.success('Project removed from archived');
      })
      .catch(() =>
        message.error(
          'Sorry there was an error un-archiving your project. Please try again later'
        )
      );
  };

  const handleArchive = () => {
    archiveProject(project, true)
      .then(() => {
        setProjectChanges({
          ...projectChanges,
          archived: true,
        });
        message.success('Project archived');
      })
      .catch(() =>
        message.error(
          'Sorry there was an error archiving your project. Please try again later'
        )
      );
  };

  const saveChanges = (updatedProject) => {
    const errorString = validateAutoPaySettings(updatedProject.autoPay);

    if (!errorString) {
      updateProject(updatedProject)
        .then(() => {
          message.success('Project was updated');
          cancelEdit();
        })
        .catch(() => {
          message.error('Error updating project. Please try again later');
        });
    } else {
      message.error(errorString);
    }
  };

  const addUser = async () => {
    if (!email) {
      message.error('Please add a valid email');
      return;
    }

    // Returns an object with details about if the user exists
    // If exists returns {id: String, already: Boolean, inDb: Boolean}
    // Added here because only time used in file - if needed elsewhere move
    const userExists = () =>
      new Promise((resolve, reject) => {
        db.collection('users')
          .where('email', '==', email.toLowerCase())
          .get()
          .then((querySnapshot) => {
            let inDb = false;
            let already = false;
            let collaboratorId = '';
            querySnapshot.forEach((doc) => {
              collaboratorId = doc.id;
              project.collaborators.forEach((collaborator) => {
                if (doc.id === collaborator.user) {
                  already = true;
                }
              });
              inDb = true;
            });
            resolve({ id: collaboratorId, already, inDb });
          })
          .catch((error) => {
            message.error(`Sorry, something went wrong - ${error.message}`);
            reject(error);
          });
      });

    // 'await' waits for the db promise in the function above to resolve
    const exists = await userExists();
    if (exists.already) {
      setEmail('');
      message.error('User is already a collaborator');
    } else if (!exists.inDb) {
      showModal();
    } else if (exists.inDb && !exists.already) {
      addUserToProject(project, exists.id, role)
        .then(() => {
          setEmail('');
          message.success('User added!');
        })
        .catch((error) =>
          message.error(`Sorry, something went wrong - ${error.message}`)
        );
    }
  };

  const handleMilestoneChange = (event, type) => {
    if (type === 'date_started') {
      setProjectChanges({
        ...projectChanges,
        date_started: dayJsToTimestamp(event),
      });
    }
  };

  const handleChangeCurrency = (event) => {
    setProjectChanges({
      ...projectChanges,
      currency: event,
    });

    setCurrency(event);
  };

  const handleNameChange = (event, type) => {
    if (type === 'name') {
      setProjectChanges({
        ...projectChanges,
        name: event.target.value,
      });
    }
  };

  const showModal = () => {
    setVisible(true);
  };

  const inviteVendorEmailChanged = (event) => {
    setEmail(event.target.value);
  };

  const handleChangeRole = (event) => {
    setRole(event);
  };

  const handleSendInvite = () => {
    setLoading(true);

    createInviteForProject(email, currentUser.id, project, role)
      .then(() => {
        setLoading(false);
        setVisible(false);
        setEmail('');
        setProjectChanges(project);
      })
      .catch((error) => {
        message.error(error.message);
        setLoading(false);
        setVisible(false);
        setEmail('');
      });
  };

  const handleChangeNotificationSettings = {
    completion: () => {
      const update = !projectChanges.notificationSettings.completion;
      projectChanges.notificationSettings.completion = update;
      setProjectChanges({ ...projectChanges });
    },
    reminder: () => {
      const update = !projectChanges.notificationSettings.reminder;
      projectChanges.notificationSettings.reminder = update;
      setProjectChanges({ ...projectChanges });
    },
  };

  const handleCancel = () => {
    setVisible(false);
  };

  if (project) {
    return (
      <>
        <Modal
          open={visible}
          onOk={handleSendInvite}
          closable={false}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleSendInvite}
            >
              Send Invitation
            </Button>,
          ]}
        >
          <span className="app-dialog-header">Send Invite?</span>
          <p style={{ marginTop: '10px' }}>
            It looks like {email} is not on DevPay yet. Would you like to send
            them an invitation to join?
          </p>
        </Modal>
        <div>
          <div>
            <div className="edit-header">Project Name</div>

            <Input
              value={projectChanges.name}
              style={{ marginTop: '10px', width: '80%' }}
              onInput={(e) => handleNameChange(e, 'name')}
            />

            <div className="edit-header" style={{ marginTop: '20px' }}>
              Started Date
            </div>

            <DatePicker
              onChange={(date, dateString) =>
                handleMilestoneChange(date, 'date_started')
              }
              value={
                projectChanges.date_started
                  ? dayjs(projectChanges.date_started.toDate())
                  : null
              }
            />

            <div style={{ marginTop: '35px' }}>
              <div className="edit-header" style={{ marginBottom: '10px' }}>
                Collaborators
              </div>
              {project.collaborators.map((collaborator) => {
                if (currentUser.id === collaborator.user) {
                  currentUser.role = collaborator.role;
                }
                const collaboratorData =
                  allCollaborators[collaborator.user].data;

                if (collaboratorData) {
                  collaboratorData.role = collaborator.role;
                  return (
                    <EditCollaboratorCard
                      key={collaborator.user}
                      collaborator={collaboratorData}
                      project={project}
                      currentUser={currentUser}
                      removeCollaboratorFromProject={
                        removeCollaboratorFromProject
                      }
                      updateCollaboratorRoleInProject={
                        updateCollaboratorRoleInProject
                      }
                    />
                  );
                }
                return <Card style={{ height: 63, border: 'none' }} loading />;
              })}
              {project.invites && project.invites.length > 0 && (
                <div style={{ clear: 'both', marginBottom: '10px' }}>
                  <span style={{ color: '#b3adad', fontSize: '17px' }}>
                    Invited
                  </span>
                </div>
              )}
              {project.invites.map((invite) => {
                return (
                  <EditCollaboratorInviteCard
                    currentUser={currentUser}
                    key={invite}
                    isInvite
                    invite={invites[invite]}
                    updateInviteRoleInProject={updateInviteRoleInProject}
                    removeInviteFromProject={removeInviteFromProject}
                  />
                );
              })}
            </div>

            <div>
              <div
                className="edit-header"
                style={{
                  marginTop: '15px',
                }}
              >
                Add Collaborator
              </div>

              <Input
                className="email-input"
                placeholder="User Email"
                value={email}
                onChange={inviteVendorEmailChanged}
              />

              <div className="add-user-section">
                <Select
                  defaultValue="viewer"
                  style={{ width: 120, marginRight: '10px' }}
                  onChange={handleChangeRole}
                >
                  <Option value="viewer">Viewer</Option>
                  <Option value="payer">Payer</Option>
                  <Option value="payee">Payee</Option>
                </Select>
                <Button onClick={addUser} type="primary">
                  Add User
                </Button>
              </div>
            </div>

            <div>
              <div
                className="edit-header"
                style={{
                  marginTop: '25px',
                }}
              >
                Notification Settings
              </div>
              <div className="edit-notification-switch-label">
                Completion Emails
                <Switch
                  style={{ float: 'right' }}
                  checked={projectChanges.notificationSettings.completion}
                  onChange={handleChangeNotificationSettings.completion}
                />
              </div>
              <div className="edit-notification-switch-label">
                Reminder Emails
                <Switch
                  style={{ float: 'right' }}
                  checked={projectChanges.notificationSettings.reminder}
                  onChange={handleChangeNotificationSettings.reminder}
                />
              </div>
            </div>

            <AutoPaySettings
              project={project}
              updateSettings={(autoPaySettings) =>
                setProjectChanges({
                  ...projectChanges,
                  autoPay: { ...autoPaySettings },
                })
              }
              collaborators={project.collaborators.map(
                (collaborator) => allCollaborators[collaborator.user].data
              )}
            />

            <DefaultPayerSetting
              project={project}
              updateSettings={(defaultPayerSettings) =>
                setProjectChanges({
                  ...projectChanges,
                  defaultPayer: defaultPayerSettings.defaultPayer,
                })
              }
              collaborators={project.collaborators.map(
                (collaborator) => allCollaborators[collaborator.user].data
              )}
              style={{ marginBottom: '20px' }}
            />

            <div>
              <div
                className="edit-header"
                style={{
                  marginTop: '25px',
                }}
              >
                Currency Settings
              </div>
              <Select
                defaultValue="USD"
                value={currency}
                style={{ width: 120, marginRight: '10px' }}
                onChange={handleChangeCurrency}
              >
                <Option value="USD">USD</Option>
                <Option value="EUR">EUR</Option>
              </Select>
            </div>
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <div>
            {currentUser.acc_type === 2 && !projectChanges.archived && (
              <div className="left-button-section">
                <Popconfirm
                  title="Project will appear in your archives, do you wish to proceed?"
                  onConfirm={handleArchive}
                  okText="I understand"
                  cancelText="Nevermind"
                >
                  <Button className="project-header-archive-button">
                    Archive Project
                  </Button>
                </Popconfirm>
              </div>
            )}
          </div>

          <div>
            {currentUser.acc_type === 2 && projectChanges.archived && (
              <div className="left-button-section">
                <Button className="unarchive-button" onClick={handleUnarchive}>
                  Unarchive Project
                </Button>
              </div>
            )}
          </div>

          <div className="right-button-section">
            <Button
              className="save-button"
              onClick={() => saveChanges(projectChanges)}
            >
              Save
            </Button>
          </div>
        </div>
      </>
    );
  }

  return <Card style={{ height: 200, border: 'none' }} loading />;
}

export default connect(null, mapDispatchToProps)(EditProjectHeaderCard);
