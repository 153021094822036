import React from 'react';
import { HiChevronDown, HiChevronRight } from 'react-icons/hi';
import MilestoneCard from '../Milestone';
import { INVOICE_STATUS_OPTIONS } from '../Milestone/constants';
import { Card } from '../Objects';

export default function MilestoneGroup({
  type,
  openSections,
  setOpenSections,
  milestones,
  editing,
  setEditing,
  currentUser,
  project,
  payer,
  conversionRates,
}) {
  const relevantMilestones = _.groupBy(milestones, 'status')[type]?.sort(
    (a, b) => {
      if (type === 'pending_approval') {
        return a.due_date - b.due_date;
      }

      // Change this to created date
      return a.due_date - b.due_date;
    }
  );
  const expanded = openSections.includes(type);

  if (
    type === 'declined' &&
    (!relevantMilestones || relevantMilestones.length === 0)
  ) {
    return null;
  }

  if (
    type === 'processing' &&
    (!relevantMilestones || relevantMilestones.length === 0)
  ) {
    return null;
  }

  if (relevantMilestones && relevantMilestones.length === 0) {
    return null;
  }

  return (
    <div className="milestone-group">
      <div
        className="section-header"
        style={{
          justifyContent: 'normal',
        }}
      >
        {!expanded && <HiChevronRight className="section-chevron" />}{' '}
        {expanded && <HiChevronDown className="section-chevron" />}
        <div
          className="section-title"
          style={{
            color: INVOICE_STATUS_OPTIONS[type].color,
          }}
          onClick={() => {
            if (expanded) {
              // Remove it
              setOpenSections(openSections.slice().filter((e) => e !== type));
            } else {
              const openSectionsCopy = openSections.slice();
              openSectionsCopy.push(type);
              setOpenSections(openSectionsCopy);
            }
          }}
          marginBottom="0px"
        >
          {INVOICE_STATUS_OPTIONS[type].section_title} (
          {relevantMilestones?.length || 0})
        </div>
      </div>
      {expanded && (
        <div>
          {relevantMilestones &&
            relevantMilestones.map((milestone) => (
              <Card>
                <MilestoneCard
                  currentUser={currentUser}
                  milestone={milestone}
                  project={project}
                  payer={payer}
                  conversionRates={conversionRates}
                  editing={editing}
                  setEditing={setEditing}
                />
              </Card>
            ))}
        </div>
      )}
    </div>
  );
}
