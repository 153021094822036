import { db } from '../fire';
// eslint-disable-next-line import/no-cycle
import { updateProject } from './project';

// Collaborator state data model
// export const collaborators = {
//   id: {
//     data: {
//       data: 'test',
//     },
//     projectRoles: {
//       projectId: 'viewer',
//     },
//   },
// };

/**
 * Basic function to load a collaborator into the store
 * @param {Object} data Collaboator data to add
 */
const loadCollaborator = data => dispatch => {
  dispatch({
    type: 'LOAD_COLLABORATOR',
    data,
  });
};

/**
 * Load user data to store
 * @param {String} collaborator collaborator object from firebase
 * @returns {Promise}
 */
// eslint-disable-next-line import/prefer-default-export
export const fetchCollaborator = (collaboratorId, projectId, role) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    let collaborator = getState().collaborators[collaboratorId];
    const data = {};
    if (!collaborator) {
      // Run dispatch once to load id into store and so do only one db call per user
      data[collaboratorId] = {
        projectRoles: {},
      };
      data[collaboratorId].projectRoles[projectId] = role;
      dispatch(loadCollaborator(data));

      db.collection('users')
        .doc(collaboratorId)
        .get()
        .then(doc => {
          collaborator = {
            id: collaboratorId,
            data: doc.data(),
            projectRoles: {},
          };
          collaborator.projectRoles[projectId] = role;
          data[collaboratorId] = collaborator;
          dispatch(loadCollaborator(data));
          resolve(collaborator);
        })
        .catch(error => reject(error));
    } else {
      collaborator.projectRoles[projectId] = role;
      data[collaboratorId] = collaborator;
      dispatch(loadCollaborator(data));
      resolve(collaborator);
    }
  });

/**
 * Remove collaborator from project
 * @param {Object} collaborator
 * @param {Object} project
 */
export const removeCollaboratorFromProject = (collaborator, project) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    let index = 0;
    project.collaborators.forEach(item => {
      if (item.user === collaborator.id) {
        if (index > -1) {
          project.collaborators.splice(index, 1);
        }
      } else {
        index += 1;
      }
    });

    project.collaborator_ids.forEach(item => {
      if (item === collaborator.id) {
        if (index > -1) {
          project.collaborator_ids.splice(index, 1);
        }
      } else {
        index += 1;
      }
    });

    if (
      project.autoPay &&
      project.autoPay.payer &&
      project.autoPay.payer.id === collaborator.id
    ) {
      project.autoPay = { enabled: false };
    }

    dispatch(updateProject(project))
      .then(() => {
        const updatedCollaborator = getState().collaborators[collaborator.id];
        delete updatedCollaborator.projectRoles[project.id];
        dispatch({
          type: 'LOAD_COLLABORATOR',
          updatedCollaborator,
        });
        resolve(project);
      })
      .catch(error => reject(error));
  });

/**
 * Update collaborator role in project
 * @param {Object} collaborator
 * @param {Object} project
 * @param {String} role
 */
export const updateCollaboratorRoleInProject = (
  collaborator,
  project,
  role
) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const projectCollaborators = project.collaborators;
    if (projectCollaborators) {
      project.collaborators.forEach(item => {
        if (item.user === collaborator.id) {
          item.role = role;
        }
      });
    }

    const updateCollaborator = getState().collaborators[collaborator.id];

    updateCollaborator.projectRoles[project.id] = role;
    dispatch(updateProject(project))
      .then(() => {
        dispatch(loadCollaborator(updateCollaborator));
        resolve(collaborator);
      })
      .catch(error => reject(error));
  });
