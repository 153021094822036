import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import styled from 'styled-components';
import { ModalHeader, ModalTitle, ModalCloseButton } from './styles';
import Receipt from './receipt';
import Invoice from './invoice';
import { useSelector } from 'react-redux';
import { getPaymentsForInvoice } from './utils';
import aloaLogo from '../../../images/aloa_small_line.png';
import { getEurConversionRate } from '../../../utils';

const organization = {
  name: 'Aloa',
  image: aloaLogo,
  help_options: {
    email: {
      enabled: true,
      value: 'support@aloa.co',
    },
  },
};

export default function Viewer({ close }) {
  const { invoice, pdfType } = useSelector((state) => state.appDialog);
  const collaborators = useSelector((state) => state.collaborators);

  const [conversionRate, setConverstionRate] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (invoice.project.currency === 'EUR') {
      getEurConversionRate()
        .then((rate) => setConverstionRate(rate))
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  const fileName =
    pdfType === 'invoice'
      ? `Invoice-${invoice.id}.pdf`
      : `Receipt-${invoice.id}.pdf`;

  const getPayer = () => {
    if (invoice.paymentData?.customer) {
      return invoice.paymentData.customer;
    }

    const defaultPayer = invoice.project.defaultPayer;

    if (defaultPayer) {
      return invoice.project.defaultPayer;
    }

    const firstPayer = invoice.project.collaborators.find(
      (collaborator) => collaborator.role === 'payer'
    );

    return collaborators[firstPayer.user].data;
  };

  const document = () => {
    if (pdfType === 'receipt') {
      return (
        <Receipt
          organization={organization}
          invoice={invoice}
          payments={getPaymentsForInvoice(invoice)}
          conversionRate={conversionRate}
          payer={getPayer()}
        />
      );
    }
    if (pdfType === 'invoice') {
      return (
        <Invoice
          organization={organization}
          invoice={invoice}
          payments={getPaymentsForInvoice(invoice)}
          conversionRate={conversionRate}
          payer={getPayer()}
        />
      );
    }
    return null;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <ModalHeader>
        <ModalTitle>{pdfType === 'invoice' ? 'Invoice' : 'Receipt'}</ModalTitle>
        <PDFDownloadLink
          style={{ flexGrow: 0, marginRight: '15px' }}
          document={document()}
          fileName={fileName}
        >
          {({ loading }) =>
            loading ? 'Loading...' : <DownloadButton>Download</DownloadButton>
          }
        </PDFDownloadLink>
        <ModalCloseButton onClick={close}>Close</ModalCloseButton>
      </ModalHeader>

      {/* TODO: Make height responsive for different screens */}
      <PDFViewer width="100%" height="800px">
        {document()}
      </PDFViewer>
    </div>
  );
}

Viewer.propTypes = {
  close: PropTypes.func,
};

Viewer.defaultProps = {
  close: () => hideModal(),
};

const DownloadButton = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
`;
