/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Button } from 'antd';

// import 'antd/dist/antd.css';

import AvatarList from 'ant-design-pro/lib/AvatarList';
import ovalOrange from '../../../images/oval_orange.svg';
import ovalGreen from '../../../images/oval_green.svg';
import ovalBlue from '../../../images/oval_blue.svg';
import ovalRed from '../../../images/oval_red.svg';
import ovalGray from '../../../images/oval_gray.svg';

import NumberFormat from 'react-number-format';

export default function ProjectCard({
  project,
  history,
  milestones,
  collaborators,
  demoOn,
  currentUser,
}) {
  const goToProject = (projectId) => {
    history.push(`project/${projectId}`);
  };

  let status = 'complete';
  const collaboratorAvatars = [];

  Object.values(milestones)
    .filter((milestone) => milestone.project_id === project.id)
    .forEach((milestone) => {
      if (milestone) {
        if (milestone.status === 'pending_approval') {
          status = 'milestones_pending';
        }

        if (
          status !== 'milestones_pending' &&
          milestone.status === 'in_progress'
        ) {
          status = 'in_progress';
        }

        if (
          status !== 'milestones_pending' &&
          status !== 'in_progress' &&
          milestone.status === 'work_pending'
        ) {
          status = 'work_pending';
        }

        if (
          status !== 'milestones_pending' &&
          milestone.status === 'declined'
        ) {
          status = 'declined';
        }

        return status;
      }
      return null;
    });

  project.collaborators.forEach((collaboratorId) => {
    const collaborator = collaborators[collaboratorId.user].data;

    if (
      collaborator != null &&
      collaborator.image != null &&
      collaborator.image !== ''
    ) {
      collaboratorAvatars.push(
        <AvatarList.Item tips={collaborator.name} src={collaborator.image} />
      );
    } else if (collaborator != null) {
      collaboratorAvatars.push(
        <AvatarList.Item
          tips={collaborator.name}
          src="https://firebasestorage.googleapis.com/v0/b/hermes-50f48.appspot.com/o/default_avatar.jpg?alt=media&token=4828d53a-5240-448c-8647-30464a6240f5"
        />
      );
    }
  });

  let runningTotal = 0;
  Object.values(milestones)
    .filter((milestone) => milestone.project_id === project.id)
    .forEach((milestone) => {
      if (milestone && milestone.amount !== '') {
        runningTotal += parseInt(milestone.amount, 10);
      }
    });

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <div className="header-section">
          <div
            style={{
              fontFamily: 'Avenir Next',
              fontSize: '18px',
              color: '#000000',
              fontWeight: 500,
            }}
          >
            {project.name}
          </div>

          <div style={{ paddingTop: 5, float: 'left', width: '100%' }}>
            <AvatarList size="small">{collaboratorAvatars}</AvatarList>
          </div>
        </div>

        <div className="status-section">
          {status === 'milestones_pending' && (
            <div className="status-message" style={{ color: '#FFB788' }}>
              Milestones Pending
              <img className="status-img" src={ovalOrange} />
            </div>
          )}

          {status === 'complete' && (
            <div className="status-message" style={{ color: '#28B300' }}>
              Project Complete
              <img className="status-img" src={ovalGreen} />
            </div>
          )}

          {status === 'in_progress' && (
            <div className="status-message" style={{ color: '#008FFF' }}>
              Work in Progress
              <img className="status-img" src={ovalBlue} />
            </div>
          )}

          {status === 'declined' && (
            <div className="status-message" style={{ color: '#FFB8B1' }}>
              Milestone Declined
              <img className="status-img" src={ovalRed} />
            </div>
          )}

          {status === 'work_pending' && (
            <div className="status-message">
              Work Pending
              <img className="status-img" src={ovalGray} />
            </div>
          )}

          <br />
          <div className="milestone-length-header">
            {milestones &&
              Object.values(milestones).filter(
                (milestone) => milestone.project_id === project.id
              ).length}{' '}
            Milestones
            {!demoOn && currentUser.acc_type === 2 && (
              <span className="running-total">
                (
                <NumberFormat
                  value={runningTotal}
                  displayType="text"
                  thousandSeparator
                  decimalScale={0}
                  fixedDecimalScale
                  prefix="$"
                />
                )
              </span>
            )}
          </div>
        </div>
      </div>

      <div style={{ float: 'right', paddingTop: 10 }}>
        <Button
          className="view-project-button"
          onClick={() => {
            goToProject(project.id);
          }}
        >
          View Project
        </Button>
      </div>

      <div style={{ clear: 'right' }} />
    </div>
  );
}
