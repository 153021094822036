/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useSelector } from 'react-redux';
import { Avatar } from 'antd';
import styled from 'styled-components';
import { currencyFormat, isAdmin } from '../../../utils';

export default function CollaboratorCard({ collaborator, invite, isInvite }) {
  const currentUser = useSelector(state => state.currentUser);

  if ((!isInvite && !collaborator) || (isInvite && !invite)) {
    return null;
  }

  const makeInitials = () => {
    if (isInvite) {
      return invite.email.substring(0, 2).toUpperCase();
    }
    const { name } = collaborator;
    const initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  };

  const shouldShowCredits =
    isAdmin(currentUser) && collaborator && collaborator.credits > 0;

  return (
    <CardContainer className="clearfix">
      {collaborator && collaborator.image ? (
        <Avatar
          className="avatar-img"
          shapre="square"
          size="large"
          src={collaborator.image}
        />
      ) : (
        <Avatar className="avatar-initials" shapre="square" size="large">
          {makeInitials()}
        </Avatar>
      )}

      <TextSection>
        <div className="collaborator-name">
          {isInvite ? invite.email : collaborator.name}
        </div>

        <NameCompanyRole>
          {isInvite ? (
            '(invited)'
          ) : (
            <div>
              {collaborator.company && `${collaborator.company} `}
              {`(${collaborator.role})`}
            </div>
          )}
        </NameCompanyRole>

        {shouldShowCredits && (
          <Credits>
            {`Credits: ${currencyFormat(collaborator.credits, 2)}`}
          </Credits>
        )}
      </TextSection>
    </CardContainer>
  );
}

const CardContainer = styled.div`
  margin-top: 20px;
  clear: both;
`;

const TextSection = styled.div`
  float: left;
  max-width: 72%;
`;

const NameCompanyRole = styled.div`
  veritcal-align: middle;
`;

const Credits = styled.div`
  float: left;
`;
