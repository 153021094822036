/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import MilestoneTable from './MilestoneTable';
import DataCard from '../Dashboard/DataCards/DataCard.js';
import { aggregateAmountForStatus, averageOverdueDays } from '../../utils';
import './Analytics.scss';
import { Route, Link } from 'react-router-dom';

const mapStateToProps = (state) => ({
  projects: state.projects,
  currentUser: state.currentUser,
  milestones: state.milestones,
  loading: state.loading,
  collaborators: state.collaborators,
});

function ARSummary({
  projects,
  currentUser,
  milestones,
  collaborators,
  loading,
}) {
  if (!loading) {
    const arMilestones = [];
    const validMilestonesIds = [];
    const milestonesPendingApproval = [];

    const radialChartData = [];

    const projectData = Object.values(projects).filter(
      (project) => !project.archived
    );

    projectData.forEach(function (project) {
      const chartData = { name: project.name, amount: 0 };
      let pushToRadial = false;

      Object.values(milestones)
        .filter((milestone) => milestone.project_id === project.id)
        .forEach(function (milestone) {
          if (milestone.status == 'pending_approval') {
            pushToRadial = true;
            milestone.projectInfo = project;
            arMilestones.push(milestone);
            chartData.amount += Number(milestone.amount);

            validMilestonesIds.push(milestone.id);

            milestonesPendingApproval.push(milestone);
          }
        });
      if (pushToRadial) {
        radialChartData.push(chartData);
      }
    });

    const aggregateStatusData = aggregateAmountForStatus(
      'pending_approval',
      projectData,
      milestones
    );

    return (
      <div className="analyticsPage">
        <div style={{ marginBottom: '20px' }}>
          <div className="headerContainer">
            <Link to="/">
              <img
                style={{ height: '20px' }}
                src={require('../../images/dash-back.svg')}
              />{' '}
            </Link>
          </div>

          <div className="headerTitle">Accounts Receivable</div>
        </div>

        <Row type="flex" justify="space-between">
          <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
            <DataCard
              title="Amount owed"
              subtitle={`${aggregateStatusData.aggregateTotalMilestones} Milestones`}
              moneyData={aggregateStatusData.aggregateTotalAmount}
            />
          </Col>
          <Col xs={{ span: 11 }} md={{ span: 11 }} lg={{ span: 11 }}>
            <DataCard
              title="Days Overdue"
              subtitle="Average"
              timeData={averageOverdueDays(milestonesPendingApproval)}
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
            <DataCard
              radialChartData={radialChartData}
              totalAR={aggregateStatusData.aggregateTotalAmount}
            />
          </Col>
        </Row>

        <div className="section-header">
          Milestones (Pending Client Approval)
        </div>

        <MilestoneTable
          arMilestones={arMilestones}
          milestones={milestones}
          projects={projectData}
          milestonesPendingApproval={milestonesPendingApproval}
          validMilestones={validMilestonesIds}
        />
      </div>
    );
  }
  return (
    <div className="App">
      <div className="dashPage">
        <h1 className="sectionTitle">Loading Analytics...</h1>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(ARSummary);
