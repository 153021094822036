/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Input, Button, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import {
  showAddPayment,
  showMilestoneActions,
  hideAppDialog,
  agreeToMilestone,
  payRemainingMilestoneAmount,
  rejectMilestone,
  updateMilestoneStatus,
} from '../../../actions/appDialog';
import './MilestoneActionsDialogContent.scss';
import SelectPaymentMethod from './SelectPaymentMethod';
import SelectPaymentAmount from './SelectPaymentAmount';
import AloaCreditSelect from './AloaCreditSelect';
import status from '../../Milestone/constants';
import { isFeatureEnabledForUser } from '../../FeatureToggle';
import featuresTypes from '../../FeatureToggle/featuresTypes';
import PaymentComplete from './PaymentComplete';
import { currencyFormat } from '../../../utils';

const { TextArea } = Input;
const showMessage = message;

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  newStatus: state.appDialog.newStatus,
  milestone: state.appDialog.milestone,
  project: state.appDialog.project,
  defaultPaymentMethod: state.appDialog.paymentMethod,
  showPdf: state.appDialog.showPdf,
  features: state.features,
});

const mapDispatchToProps = {
  showAddPayment,
  showMilestoneActions,
  hideAppDialog,
  agreeToMilestone,
  payRemainingMilestoneAmount,
  rejectMilestone,
  updateMilestoneStatus,
};

function MilestoneActionsDialogContent({
  currentUser,
  newStatus,
  milestone,
  project,
  defaultPaymentMethod,
  showAddPayment,
  showMilestoneActions,
  hideAppDialog,
  agreeToMilestone,
  payRemainingMilestoneAmount,
  rejectMilestone,
  updateMilestoneStatus,
  showPdf,
  features,
  visible,
  callback,
}) {
  const [paymentMethod, setPaymentMethod] = useState(defaultPaymentMethod);
  const [declineReason, setDeclineReason] = useState(milestone.decline_reason);
  const [actionLoading, setActionLoading] = useState(false);
  const [approveDisabled, setApproveDisaled] = useState(false);
  const [paymentComplete, setPaymentComplete] = useState(false);
  const [creditsToUse, setCreditsToUse] = useState(
    Math.min(
      currentUser.credits ? currentUser.credits : 0,
      Number(milestone.amount)
    )
  );

  const maxCCEligibleAmount = 3000;
  const creditCardEligible =
    milestone.amount - creditsToUse <= maxCCEligibleAmount ||
    !isFeatureEnabledForUser(
      features[featuresTypes.CREDIT_CARD_ELIGIBILITY_LIMIT],
      currentUser.id
    );

  // Changes to support multiple payments
  const pendingPayments = project.payments
    ? project.payments.filter(payment => !payment.paid)
    : [];
  const [paymentAmount, setPaymentAmount] = useState(
    pendingPayments.length > 0 ? pendingPayments[0].amount : 0
  );
  const [paymentSelected, setPaymentSelected] = useState(false);

  useEffect(() => {
    if (!defaultPaymentMethod) {
      if (currentUser.payment_methods && currentUser.payment_methods.length) {
        const validPaymentMethods = currentUser.payment_methods.filter(
          method =>
            method.verified && !(method.type === 'card' && !creditCardEligible)
        );
        if (validPaymentMethods.length > 0) {
          setApproveDisaled(false);
          setPaymentMethod(validPaymentMethods[0].source_id);
        } else {
          setApproveDisaled(true);
        }
      } else {
        setApproveDisaled(true);
      }
    } else {
      setApproveDisaled(
        !creditCardEligible &&
          (defaultPaymentMethod.object === 'card' ||
            defaultPaymentMethod.type === 'card')
      );
      setPaymentMethod(defaultPaymentMethod.id);
    }
  }, [defaultPaymentMethod, visible, creditsToUse]);

  useEffect(() => {
    if (declineReason !== milestone.decline_reason) {
      setDeclineReason(milestone.decline_reason);
    }
  }, [milestone]);

  const { title, message } = textForNewStatus(newStatus);

  const handleSetPaymentMethod = paymentMethodSourceId => {
    const selectedPaymentMethod = currentUser.payment_methods.filter(
      method => method.source_id === paymentMethodSourceId
    )[0];

    setPaymentMethod(paymentMethodSourceId);
    if (selectedPaymentMethod) {
      setApproveDisaled(
        !creditCardEligible && selectedPaymentMethod.type === 'card'
      );
    } else {
      setApproveDisaled(false);
    }
  };

  const handlePrimaryActions = () => {
    setActionLoading(true);
    if (
      newStatus === status.inProgress ||
      newStatus === status.pendingApproval
    ) {
      updateMilestoneStatus(milestone, project, newStatus, true)
        .then(result => {
          showMessage.success(result.message);
          setActionLoading(false);
        })
        .catch(error => {
          showMessage.error(error.message);
          setActionLoading(false);
        });
    }
    if (newStatus === status.paid && !milestone.amountPending) {
      if (!approveDisabled) {
        agreeToMilestone(milestone, project, paymentMethod, creditsToUse, false)
          .then(() => {
            showMessage.success('Milestone sucessfully approved!');
            setCreditsToUse(0);
            setPaymentComplete(true);
            setActionLoading(false);
          })
          .catch(error => {
            showMessage.error(error.message);
            setActionLoading(false);
          });
      } else {
        showMessage.error('Please add or select a valid payment method');
        setActionLoading(false);
      }
    }
    if (newStatus === status.paid && milestone.amountPending) {
      if (paymentSelected) {
        payRemainingMilestoneAmount(milestone, project, paymentMethod)
          .then(() => {
            showMessage.success('Milestone sucessfully approved!');
            setActionLoading(false);
          })
          .catch(error => {
            showMessage.error(error.message);
            setActionLoading(false);
          });
      } else {
        setActionLoading(false);
        setPaymentSelected(true);
      }
    }
    if (newStatus === status.declined) {
      // Probably could use finally, but don't want console to complain
      // about an uncaught error in Promise
      if (declineReason) {
        rejectMilestone(milestone, project, declineReason)
          .then(() => setActionLoading(false))
          .catch(() => setActionLoading(false));
      } else {
        setActionLoading(false);
        showMessage.error('You must provide a reason when rejecting');
      }
    }
  };

  if (paymentComplete) {
    return (
      <>
        <span className="app-dialog-header">Payment Complete</span>
        <PaymentComplete
          project={project}
          milestone={milestone}
          showPdf={showPdf}
          cancelActive={() => {
            hideAppDialog();
            setTimeout(() => setPaymentComplete(false), 1000);
          }}
        />
      </>
    );
  }

  return (
    <>
      <span className="app-dialog-header">{title}</span>
      {!(currentUser.payment_methods && currentUser.payment_methods.length) ? (
        <div className="partner-message">{noPaymentMethodMessage}</div>
      ) : (
        <>
          <div className="partner-message">{message}</div>

          {newStatus === status.pendingApproval &&
            project.autoPay &&
            project.autoPay.enabled && (
              <AutoPayMessage>
                <ExclamationCircleOutlined style={{ marginRight: '5px' }} />
                Note: Auto Pay is enabled on this project. By clicking agree,
                <AutoPayer> {project.autoPay.payer.name}</AutoPayer> will
                automatically be charged.
              </AutoPayMessage>
            )}

          {newStatus === status.paid && !paymentSelected && (
            <>
              {currentUser.credits > 0 && (
                <AloaCreditSelect
                  currentUser={currentUser}
                  milestone={milestone}
                  creditsToUse={creditsToUse}
                  setCreditsToUse={setCreditsToUse}
                />
              )}
              <SelectPaymentMethod
                currentUser={currentUser}
                paymentMethod={paymentMethod}
                setPaymentMethod={handleSetPaymentMethod}
                hideAppDialog={hideAppDialog}
                creditCardEligible={creditCardEligible}
                paymentMethodRequired={milestone.amount - creditsToUse > 0}
              />
            </>
          )}
          {newStatus === status.paid && paymentSelected && (
            <SelectPaymentAmount
              project={project}
              milestone={milestone}
              paymentAmount={paymentAmount}
              setPaymentAmount={setPaymentAmount}
              paymentMethod={paymentMethod}
            />
          )}
        </>
      )}
      {newStatus === status.paid && !paymentSelected && (
        <div style={{ paddingTop: '10px' }}>
          <div
            className="add-payment-div-app-dialog"
            to="/addPaymentMethod"
            target="_blank"
            onClick={() => {
              showAddPayment(
                {
                  project,
                  milestone,
                },
                paymentMethod => {
                  showMilestoneActions(
                    {
                      milestone,
                      project,
                      newStatus,
                      paymentMethod,
                    },
                    callback
                  );
                }
              );
            }}
          >
            Add Payment Method
          </div>
        </div>
      )}
      {newStatus === status.declined && (
        <TextArea
          style={{ marginTop: '30px' }}
          value={declineReason}
          onChange={event => setDeclineReason(event.target.value)}
          placeholder="Login was not implemented fully, etc..."
          autosize={{ minRows: 3, maxRows: 8 }}
        />
      )}
      <div style={{ marginTop: '20px', width: '100%', textAlign: 'right' }}>
        {newStatus === status.paid && (
          <div style={{ marginBottom: '10px' }}>
            Amount to be Charged:{' '}
            {currencyFormat(milestone.amount - creditsToUse, 2)}
          </div>
        )}
        <Button
          onClick={() => {
            if (paymentSelected) {
              setPaymentSelected(false);
            } else {
              callback();
            }
          }}
          style={{ marginRight: '10px' }}
        >
          Cancel
        </Button>
        <Button
          type={approveDisabled ? 'disabled' : 'primary'}
          loading={actionLoading}
          onClick={handlePrimaryActions}
        >
          {newStatus === status.paid &&
            (!milestone.amountPending || paymentSelected) &&
            `Approve & Pay`}
          {newStatus === status.paid &&
            milestone.amountPending &&
            !paymentSelected &&
            `Select Payment Amount`}
          {newStatus === status.declined && `Send Feedback`}
          {(newStatus === status.inProgress ||
            newStatus === status.pendingApproval) &&
            'Agree'}
        </Button>
      </div>
    </>
  );
}

const textForNewStatus = newStatus => {
  let title = 'Review Payment';
  let message = 'Please select your payment method of choice';

  switch (newStatus) {
    case status.paid:
      title = 'Payment Confirmation';
      message =
        "By clicking 'Agree & Pay' you approve of the milestone and authorize release of payment";
      break;
    case status.pendingApproval:
      title = 'Completion Confirmation';
      message =
        'By clicking agree, you agree that the full scope of the milestone has been delivered and the client will be notified';
      break;
    case status.inProgress:
      title = 'Work Beginning Confirmation';
      message =
        'By clicking agree, you agree that work is commencing on the milestone and the client will be notified';
      break;
    case status.declined:
      title = 'Reject Reason';
      message =
        'Please list all reasons why the milestone was declined and changes that can be made. Please remember to only include items related to this milestone.';
      break;
    default:
  }

  return { title, message };
};

const noPaymentMethodMessage =
  "It looks like you don't have a payment method on file, please click the link below to add one. Once you've added a payment method you can approve and pay 👌";

const AutoPayMessage = styled.div`
  padding-top: 20px;
  color: #5a7685;
  line-height: 30px;
  font-size: 16px;
  font-family: Avenir Next;
  font-weight: 100;
`;

const AutoPayer = styled.span`
  color: #008fff;
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MilestoneActionsDialogContent);
