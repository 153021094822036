/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable global-require */
import React from 'react';
import { milestoneStatus } from './constants';
import {
  numberWithCommas,
  numToMonth,
  convertToCurrency,
  getConvertedAmountForMilestone,
} from '../../utils';

export default function MilestoneHeader({
  milestone,
  project,
  dashboard,
  conversionRates,
}) {
  const cardTitle = dashboard
    ? `${milestone.name} - ${project.name}`
    : `${milestone.name}`;

  return (
    <div style={{ display: 'flex' }}>
      <div className="header-top-section">
        <div className="card-title">{cardTitle}</div>

        <div className="due-date-wrapper">
          <span className="due-date">
            {milestone.due_date ? (
              <>
                Invoiced {numToMonth(milestone.due_date.toDate().getMonth())}{' '}
                {milestone.due_date.toDate().getDate()}{' '}
                {milestone.due_date.toDate().getFullYear()}{' '}
              </>
            ) : (
              <>No Invoice Date</>
            )}
          </span>
        </div>
      </div>

      <div className="header-middle-section">
        {/* Message section that has text and colored oval - see function component below */}
        <StatusMessage
          status={milestone.status}
          amountPending={milestone.amountPending}
        />

        <div className="milestone-amount">
          {!milestone.amountPending && (
            <>
              {' '}
              ${milestone.amount
                ? numberWithCommas(milestone.amount)
                : '0.00'}{' '}
              {project.currency != null && project.currency !== 'USD' && (
                <span className="secondary-amount">
                  {getConvertedAmountForMilestone({
                    conversionRates,
                    paymentAmount: milestone.paymentData
                      ? milestone.paymentData.paymentAmount
                      : null,
                    creditsAmount: milestone.paymentData
                      ? milestone.paymentData.creditsAmount
                      : null,
                    type: 'total',
                    invoiceAmount: milestone.amount,
                    invoiceCurrency: milestone.paymentData
                      ? milestone.paymentData.currency
                      : null,
                    projectCurrency: project.currency,
                    hideIfUSD: true,
                    showParentheses: true,
                  })}
                </span>
              )}
            </>
          )}
          {milestone.amountPending && (
            <>
              <div style={{ color: '#aaa' }}>
                {'Total: '}$
                {milestone.amount ? numberWithCommas(milestone.amount) : '0.00'}{' '}
              </div>
              {'Pending: '}$
              {milestone.amount
                ? numberWithCommas(milestone.amountPending)
                : '0.00'}{' '}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export function StatusMessage(props) {
  switch (props.status) {
    case milestoneStatus.paid:
      if (!props.amountPending) {
        return (
          <div
            className="status-text"
            style={{
              color: '#28B300',
            }}
          >
            Paid
            <div
              className="status-bubble"
              style={{
                backgroundColor: '#28B300',
              }}
            />{' '}
          </div>
        );
      }
      return (
        <div
          className="status-text"
          style={{
            color: '#FFB788',
          }}
        >
          Payment Pending
          <div
            className="status-bubble"
            style={{
              backgroundColor: '#FFB788',
            }}
          />{' '}
        </div>
      );

    case milestoneStatus.declined:
      return (
        <div
          className="status-text"
          style={{
            color: '#FFB8B1',
            fontWeight: 400,
          }}
        >
          Rejected
          <div
            className="status-bubble"
            style={{
              backgroundColor: '#FFB8B1',
            }}
          />
        </div>
      );
    case milestoneStatus.inProgress:
      return (
        <div
          className="status-text"
          style={{
            color: '#008FFF',
          }}
        >
          Work in Progress
          <div
            className="status-bubble"
            style={{
              backgroundColor: '#008FFF',
            }}
          />
        </div>
      );
    case milestoneStatus.pendingApproval:
      return (
        <div
          className="status-text"
          style={{
            color: '#FFB788',
            fontWeight: 400,
          }}
        >
          Pending Approval
          <div
            className="status-bubble"
            style={{
              backgroundColor: '#FFB788',
            }}
          />
        </div>
      );
    case milestoneStatus.workPending:
      return (
        <div
          className="status-text"
          style={{
            color: '#A5A5A5',
            fontWeight: 400,
          }}
        >
          Work Pending
          <div
            className="status-bubble"
            style={{
              backgroundColor: '#A5A5A5',
            }}
          />
        </div>
      );
    default:
      return null;
  }
}
