/* eslint-disable react/jsx-indent-props */
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Input, message, Button, Select, DatePicker } from 'antd';
import { useDispatch } from 'react-redux';
import { dayJsToTimestamp } from '../../utils';
import LineItemEditor from './LineItems/LineItemEditor';

import {
  updateMilestone,
  payForMilestone,
  deleteMilestone,
} from '../../actions/milestone';

const { Option } = Select;
const { TextArea } = Input;
const dateFormat = 'MM/DD/YYYY';

export default function Editing({
  project,
  milestone,
  cancelEdit,
  finishedEditing,
}) {
  const [changes, setChanges] = useState(milestone);

  const dispatch = useDispatch();

  useEffect(() => {
    setChanges(milestone);
  }, [milestone]);

  /**
   * Handle changes when editing milestone
   * @param {Event} event - Event passed when change triggered
   * @param {String} type - Type of event
   */
  const handleChange = (event, type) => {
    if (type === 'status') {
      changes.status = event;

      // If we are pending approval, set the invoice date
      if (event == 'pending_approval') {
        changes.invoice_created_date = new Date();
      }
    } else if (type === 'due_date' || type === 'net_due_date') {
      if (event) {
        changes[type] = dayJsToTimestamp(event);
      } else {
        changes[type] = null;
      }
    } else {
      changes[type] = event.target.value;
    }

    setChanges({ ...changes });
  };

  const saveChanges = () => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(changes.amount)) {
      message.error('Invalid Amount');
    } else {
      dispatch(updateMilestone(project.id, changes))
        .then(() => {
          finishedEditing();
          message.success('Updated milestone');
        })
        .catch((error) => {
          message.error(error.message);
        });
    }
  };

  useEffect(() => {
    // If there are any line item changes, let's update the amount field
    if (
      (changes.line_items && changes.line_items.length > 0) ||
      !changes.amount ||
      changes.amount === 0
    ) {
      let total = 0;

      if (changes.line_items) {
        changes.line_items.forEach((item) => {
          // First, get the quantity and rate for this item
          const quantity = item.quantity ? item.quantity : 1;
          const rate = item.rate ? item.rate : 0;

          // Then, multiply them together to get the total
          total += quantity * rate;
        });
      }

      // Cap it at 2 decimal places
      total = Math.round(total * 100) / 100;

      // Finally, set the amount to the total
      setChanges({ ...changes, amount: total });
    }
  }, [changes.line_items]);

  return (
    <>
      <div>
        <div className="edit-label" style={{ marginTop: 0 }}>
          Milestone Name
        </div>

        <Input
          className="edit-input"
          value={changes.name}
          style={{ width: '60%' }}
          onInput={(e) => handleChange(e, 'name')}
        />

        <div className="edit-label">Amount (only numbers)</div>

        {/* Amount edit input */}
        <Input
          className="edit-input"
          value={changes.amount}
          style={{ width: '30%' }}
          onInput={(e) => handleChange(e, 'amount')}
          disabled={
            // If there are any line items, don't allow this field to be edited
            changes.line_items && changes.line_items.length > 0
          }
        />

        <div className="edit-label">Invoice Date</div>

        {/* Invoice date edit input */}
        <DatePicker
          onChange={(date, dateString) => handleChange(date, 'due_date')}
          value={
            // If there is a due date, convert it from firebase timestamp to dayjs object
            changes.due_date ? dayjs(changes.due_date.toDate()) : null
          }
          placeholder="Select Date"
        />

        <div className="edit-label">
          Due Date (Do not touch unless overriding net-14)
        </div>

        {/* Invoice date edit input */}
        <DatePicker
          onChange={(date, dateString) => handleChange(date, 'net_due_date')}
          value={
            // If there is a due date, convert it from firebase timestamp to dayjs object
            changes.net_due_date ? dayjs(changes.net_due_date.toDate()) : null
          }
          placeholder="Select Date"
        />

        <div className="edit-label">Milestone Status</div>

        {/* Status edit Select component */}
        <Select
          style={{ minWidth: '100px' }}
          defaultValue={changes.status}
          value={changes.status}
          onChange={(e) => handleChange(e, 'status')}
        >
          <Option value="work_pending">Work Pending</Option>
          <Option value="in_progress">Work In Progress</Option>
          <Option value="pending_approval">Pending Client Approval</Option>
          <Option value="declined">Declined</Option>
          <Option value="paid">Paid</Option>
        </Select>

        {/* Description edit label */}

        <div className="edit-label">Line items</div>

        <LineItemEditor
          lineItems={changes.line_items}
          setLineItems={(lineItems) => {
            changes.line_items = lineItems;
            setChanges({ ...changes });
          }}
          editable
          milestoneId={milestone.id}
        />

        <div className="edit-label">Quickbooks Invoice URL</div>

        <Input
          className="edit-input"
          value={changes.quickbooks_invoice_url}
          style={{ width: '60%' }}
          onInput={(e) => handleChange(e, 'quickbooks_invoice_url')}
        />

        <div className="edit-label">Description</div>

        {/* Description edit text area */}
        <TextArea
          className="edit-input"
          value={changes.description}
          style={{ width: '80%' }}
          onInput={(e) => handleChange(e, 'description')}
        />

        {/* Rejection reason edit label */}
        <div className="edit-label">Rejection Reason (if applicable)</div>

        <TextArea
          className="edit-input"
          value={changes.decline_reason}
          style={{ width: '80%' }}
          onInput={(e) => handleChange(e, 'decline_reason')}
        />
      </div>

      {/* Bottom action buttons */}
      <div style={{ marginTop: 20 }}>
        <div style={{ float: 'left', marginTop: '10px' }}>
          <Button
            className="delete-button"
            onClick={() => {
              dispatch(deleteMilestone(project.id, milestone.id)).then(() => {
                finishedEditing();
                message.success('Deleted milestone');
              });
            }}
          >
            Delete Milestone
          </Button>
        </div>

        <div style={{ float: 'right' }}>
          <Button className="cancel-button" onClick={cancelEdit}>
            Cancel
          </Button>
          <Button
            className="milestone-save-button"
            type="primary"
            onClick={saveChanges}
          >
            Save
          </Button>
        </div>
      </div>
      <div style={{ clear: 'right' }} />
    </>
  );
}
