import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { Card } from '../../Objects';
import milestoneStatus from '../../../constants/milestoneStatuses';
import { currencyFormat } from '../../../utils';

export default function MilestoneDialogCard({
  milestone,
  index,
  selectMilestone,
}) {
  const statusContent = mapStatusToContent(milestone.status);

  return (
    <Card
      style={{ marginBottom: '0px', marginTop: index > 0 ? '10px' : '0px' }}
    >
      <LeftContainer>
        <Header>{milestone.name}</Header>
        <StatusMessage color={statusContent.color}>
          {statusContent.text}
        </StatusMessage>
        <Cost>{currencyFormat(milestone.amount)}</Cost>
      </LeftContainer>
      <RightContainer>
        <Button
          type="primary"
          style={{ marginTop: '5px' }}
          onClick={() => {
            selectMilestone(milestone.id);
          }}
        >
          Approve & Pay
        </Button>
      </RightContainer>
      <div style={{ clear: 'both' }} />
    </Card>
  );
}

const Header = styled.div`
  font-family: 'Avenir Next';
  font-size: 17px;
  color: #232323;
  font-weight: 500;
`;

const Cost = styled.div`
  font-family: 'Avenir Next';
  font-size: 17px;
`;

const RightContainer = styled.div`
  float: right;
  textalign: right;
`;

const LeftContainer = styled.div`
  float: left;
`;

const StatusMessage = styled.div`
  font-family: 'Avenir Next';
  font-size: 17px;
  color: ${props => props.color};
`;

const mapStatusToContent = status => {
  const statusContentMap = {
    [milestoneStatus.workPending]: {
      text: 'Work Pending',
      color: '#A5A5A5',
    },
    [milestoneStatus.in_progress]: {
      text: 'Work in Progress',
      color: '#008FFF',
    },
    [milestoneStatus.pending_approval]: {
      text: 'Pending Approval',
      color: '#FFB788',
    },
    [milestoneStatus.paid]: {
      text: 'Paid',
      color: '#28B300',
    },
    [milestoneStatus.declined]: {
      text: 'Declined',
      color: '#FFB8B1',
    },
  };

  return statusContentMap[status];
};
