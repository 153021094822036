import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { currencyFormatter } from '../utils';

const styles = StyleSheet.create({
  container: {
    marginTop: '50px',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'stretch',
    marginTop: '5px',
  },
  mainText: {
    fontSize: 24,
    flexGrow: 1,
    fontWeight: '200',
    fontFamily: 'Lato Bold',
    alignSelf: 'flex-end',
    justifySelf: 'flex-start',
  },
});

export default function Summary({ invoice, payment }) {
  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Text style={styles.mainText}>
          {currencyFormatter.format(invoice.amount * 100)} paid on{' '}
          {!Number.isNaN(new Date(payment.date).getTime())
            ? new Date(payment.date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
            : payment.date}
        </Text>
      </View>
    </View>
  );
}

Summary.propTypes = {
  invoice: PropTypes.object.isRequired,
  payment: PropTypes.object.isRequired,
};
