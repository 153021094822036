/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { getDate, getDueDateFromInvoiceDate } from '../utils';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  name: {
    fontSize: 28,
    flexGrow: 1,
    fontFamily: 'Open Sans',
    alignSelf: 'flex-end',
    justifySelf: 'flex-start',
  },
  image: {
    width: '80px',
  },
  invoiceHeader: {
    fontFamily: 'Open Sans',
    fontSize: 24,
    flexGrow: 0,
    color: '#888888',
    textDecoration: 'none',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
  rowMargin: {
    marginTop: '5px',
  },
  contactColumn: {
    flexDirection: 'column',
    flexGrow: 9,
  },
  contactText: {
    fontSize: 10,
    fontFamily: 'Open Sans',
  },
  invoiceData: {
    fontFamily: 'Open Sans',
    fontSize: 12,
    color: '#000000',
    textDecoration: 'none',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
  invoiceDataColumn: {
    fontFamily: 'Open Sans',
    alignSelf: 'flex-start',
    flexGrow: 1,
  },
  invoiceDataRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    fontSize: 10,
  },
  invoiceDataKey: {
    flexGrow: 2,
  },
  invoiceDataValue: {
    flexGrow: 0,
  },
});

export default function Header({ organization, invoice }) {
  return (
    <View>
      <View style={styles.row}>
        {organization.image ? (
          <View style={styles.name}>
            <Image style={styles.image} src={organization.image}></Image>
          </View>
        ) : (
          <Text style={styles.name}>{organization.name}</Text>
        )}
        <Text style={styles.invoiceHeader}>Invoice</Text>
      </View>
      <View style={{ ...styles.row, ...styles.rowMargin }}>
        <View style={styles.contactColumn}>
          {organization.help_options?.email?.enabled && (
            <Text style={styles.contactText}>
              {organization.help_options.email.value}
            </Text>
          )}
          {organization.help_options?.phone?.enabled && (
            <Text style={styles.contactText}>
              {organization.help_options.phone.value}
            </Text>
          )}
        </View>

        <View style={styles.invoiceDataColumn}>
          {[
            { key: 'Invoice Number:', value: invoice.id.toUpperCase() },
            {
              key: 'Date Created:',
              value: getDate(invoice.invoice_created_date).toLocaleDateString(
                'en-US',
                {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                }
              ),
            },
            {
              key: 'Invoice Date:',
              value: getDate(invoice.due_date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              }),
            },
            {
              key: 'Due Date:',
              value: getDueDateFromInvoiceDate(
                invoice.due_date,
                invoice.net_due_date
              ).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              }),
            },
          ].map(({ key, value }) => (
            <View key={key} style={styles.invoiceDataRow}>
              <Text style={styles.invoiceDataKey}>{key}</Text>
              <Text style={styles.invoiceDataValue}>{value}</Text>
            </View>
          ))}
        </View>
      </View>
    </View>
  );
}

Header.propTypes = {
  organization: PropTypes.object.isRequired,
  invoice: PropTypes.object.isRequired,
};
